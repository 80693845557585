@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
canvas {
  width: 100vw;
  height: 100vh;
}
canvas {
  width: 100vw;
  height: 100vh;
}

html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  width: 100vw;
  height: 100vh;
}

body {
  background: white;
  font-family: "Inter var", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

#root {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  position: absolute;
  top: 43px;
  left: 180px;
  padding: 0;
  margin: 40px;
  line-height: 0.7em;
  color: #ffffff;
}

h1 {
  font-size: 5em;
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1em;
}

.picker {
  position: absolute !important;
  top: 25px;
  left: 20px;
  width: 150px !important;
  height: 150px !important;
}

.picker__swatches {
    position: absolute !important;
    top: 10vh;
    left: 0px;
    width: 450px !important;
    height: 40px;
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    padding: .25em;
    z-index: 9;
}

.picker__swatch {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #005985;
  color: #fefefe;
}

a {
  line-height: inherit;
  color: #005985;
  text-decoration: none;
  cursor: pointer;
}

nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  position: absolute;
  z-index: 999;
  top: 20%;
}


.blob {
  background: hsla(190, 11%, 78%, 0.702);
  box-shadow: 0 0 0 0 rgba(242, 244, 245, 0.7);

  border-radius: 50%;
  margin: 10px;
  height: 40px;
  width: 40px;

  -webkit-transform: scale(1.2);

          transform: scale(1.2);
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
}

.blob--bottom {
  background: hsla(190, 11%, 78%, 0.702);
  box-shadow: 0 0 0 0 rgba(242, 244, 245, 0.7);
}

.blob:hover {
  cursor: pointer;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: all 1s;
  color: white;
}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.menu.left {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.menu.right {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.menu>div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.menu>div b {
  font-weight: 600;
  color: #b0b0b0;
}

.fullscreen.ready>div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.menu a:hover {
  text-decoration: underline;
  color: inherit;
}

.pulsate {
  -webkit-animation: pulsate 2s ease-out;
          animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 1;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.6;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.6;
  }
}
